var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form"},[_c('a-form-model-item',{ref:"contractor",staticClass:"form_item",attrs:{"label":"Клиент","prop":"contractor","rules":{
            required: true,
            message: 'Обязательно для заполнения',
            trigger: 'blur'
        }}},[_c('a-select',{attrs:{"size":"large","loading":_vm.typeLoader},model:{value:(_vm.form.contractor),callback:function ($$v) {_vm.$set(_vm.form, "contractor", $$v)},expression:"form.contractor"}},_vm._l((_vm.contractorList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.string_view)+" ")])}),1)],1),_c('a-form-model-item',{ref:"contractor_member",staticClass:"form_item",attrs:{"label":"Контрагент","prop":"contractor_member","rules":{
            required: true,
            message: 'Обязательно для заполнения',
            trigger: 'blur'
        }}},[_c('a-select',{attrs:{"size":"large","loading":_vm.memberLoader},model:{value:(_vm.form.contractor_member),callback:function ($$v) {_vm.$set(_vm.form, "contractor_member", $$v)},expression:"form.contractor_member"}},_vm._l((_vm.memberList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.string_view)+" ")])}),1)],1),_c('a-form-model-item',{ref:"contract",staticClass:"form_item",attrs:{"label":"Соглашение","help":"При изменении соглашения цена в заказе будет пересчитана","prop":"contract","rules":{
            required: true,
            message: 'Обязательно для заполнения',
            trigger: 'blur'
        }}},[_c('a-select',{attrs:{"size":"large","loading":_vm.contractLoading},on:{"change":function($event){return _vm.changeContract($event, _vm.contractList)}},model:{value:(_vm.form.contract),callback:function ($$v) {_vm.$set(_vm.form, "contract", $$v)},expression:"form.contract"}},_vm._l((_vm.contractList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.code}},[_vm._v(" "+_vm._s(item.string_view)+" ")])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }